
import React from 'react';
import PhoneForm from './PhoneForm';

const PhoneBannerSection = (props) => {

    return (
        <div className='banner-section' style={{ background: `url(${'../../../../images/banner.jpg'})` }}>
            <div className='section-overlay'>
                <div className='baner-overlay'>
                    <div className='container'>
                        <div className='banner-form-content'>
                            <div className='banner-form'>
                                <img src="../../../../images/sharktank.png" alt="Icon" />
                                <h3>Shark Tank India Finalists</h3>
                                <PhoneForm />
                            </div>
                            <div className='banner-content-box'>
                                <h4>Get access to <span>Hybrid Learning</span> with a blend of unlimited Live + Recorded <span>Sessions</span> </h4>
                                <ul>
                                    <li>Top Courses include Resin Art, Concrete Art, Acrylic Art & Kids Course.</li>
                                    <li>Live Classes help you interact directly with the instructor.</li>
                                    <li>One to One doubt sessions & entrepreneurial guidance help you excel in your journey</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='banner-content-box for-mobile'>
                <h4>Get access to <span>Hybrid Learning</span> with a blend of unlimited Live + Recorded <span>Sessions</span> </h4>
                <ul>
                    <li>Top Courses include Resin Art, Concrete Art, Acrylic Art & Kids Course.</li>
                    <li>Live Classes help you interact directly with the instructor.</li>
                    <li>One to One doubt sessions & entrepreneurial guidance help you excel in your journey</li>
                </ul>
            </div>
        </div>
    )

};

export default PhoneBannerSection;
