import React from 'react'
import {Route} from 'react-router-dom';
import PhoneNumber from '../PhoneNumber/PhoneNumber';


const MainRoute = ({component: Component}) => {

    return (
        <Route
            render={props => (
                <div className="auth-screen">
                    <Route exact path="/phone" component={PhoneNumber}/>
                </div>
            )}
        />
    )
};

export default MainRoute;
