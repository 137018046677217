
import React from 'react';

const FixedContent = (props) => {

    return (
        <div className='fixed-section'>
            <div className='container'>
                <div className='fixed-box'>
                    <div className='fixed-left'>
                        <div className='content-box'>
                            <h5>Art kit delivery at your doorstep</h5>
                            <p>You can order your favoirite material kit and enjoy the wholesome academic experience at the comfort of your home.</p>
                        </div>
                        <img src="../../../../images/fixed-left.png" alt="Image" />
                    </div>
                    <div className='seprater'>
                        <img src="../../../../images/seprater.svg" alt="Seprater" />
                    </div>
                    <div className='fixed-right'>
                        <div className='content-box'>
                            <h5>What will you explore ?</h5>
                            <p>You can find the courses that compliment your passion & your entrepreneurial Art journey.</p>
                        </div>
                        <img src="../../../../images/fixed-right.png" alt="Image" />
                    </div>
                </div>
            </div>
        </div>
    )

};

export default FixedContent;