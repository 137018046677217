import React from 'react'
import {Route} from 'react-router-dom';
import Payment from '../Main/Payment/Payment';


const PaymentRoute = ({component: Component}) => {

    return (
        <Route
            render={props => (
                <div className="auth-screen">
                    <Route exact path="/payment" component={Payment}/>
                </div>                    
            )}
        />
    )
};

export default PaymentRoute;