import { Button } from 'bootstrap';
import React from 'react';

const Header = (props) => {

    return (
        <div className='header-main'>
            <div className='logo-box'>
                <img src="../../../../images/logo.svg" alt="Logo" />
            </div>
            <div className='signin-box'>
                {props.butontext &&
                    <button onClick={()=>{
                    window.location.href = "https://learnresin.in/login/otp"
                    }}>{props.butontext}</button>
                }
                {props.skiptext &&
                    <button className='skip' onClick={()=>{
                        window.location.href = "https://learnresin.in/browses"
                    }}>{props.skiptext}</button>
                }
            </div>
        </div>
    )

};

export default Header;
