import React, {useEffect, useState} from 'react';
import SimpleButton from '../../components/Buttons/SimpleButton';
import SimpleInput from '../../components/Input/SimpleInput';
import {useLocation, useParams} from "react-router-dom";
import axios from "axios";

function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const PhoneForm = (props) => {

    const params = useQuery();

    const [input, setInput] = useState({});
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);

    const handleChange = (event) => {
        let i = { ...input };
        i[event.target.name] = event.target.value;
        setInput(i);
    }



    //Form Submit
    const handleSubmit = async (event) => {
        event.preventDefault();
        if (validate()) {
            setLoading(true);
            try{
                let response = (await axios.post(`https://learnresin.in/api/v1/user/register/v2/social`, {...input,type: params.get("state"), code: params.get("code")})).data
                if (response.success) {
                    localStorage.setItem("user", JSON.stringify(response.response));
                    setTimeout(() => {
                        window.location.href = "/payment";
                        setLoading(false);
                    }, 2000)
                } else {
                    let errors = {};
                    errors.phone = response.error.description;
                    setErrors(errors);
                    setLoading(false);
                }
            }catch (e){
                setLoading(false);
            }
        }
    }

    // Validations
    const validate = () => {
        let errors = {};
        let isValid = true;

        if (!input["phone"]) {
            isValid = false;
            errors["phone"] = "*phone Number is required";
        } else {
            const regex = new RegExp("^([1-9]{1}[0-9]{9})$");
            if (!regex.test(input["phone"])) {
                isValid = false;
                errors["phone"] = "*Please enter a valid phone number";
            }
        }

        setErrors(errors);
        return isValid;
    }

    return (
        <div className='login-form'>
            <SimpleInput
                className={errors.phone ? "form-control error" : "form-control"}
                type="text"
                name="phone"
                placeholder="Phone Number"
                value={input.phone}
                onChange={handleChange}
                errors={errors.phone}
            />
            <SimpleButton onClick={handleSubmit} loading={loading}  name={loading ? "":"Continue"} />
        </div>
    )
}

export default PhoneForm;
