import { Button } from 'bootstrap';
import React from 'react';
import { Link } from 'react-router-dom';

const Footer = (props) => {

    return (
        <div className='footer'>
            <div className='links'>
                <a href="https://learnresin.in/bundles/itmaticmavenika/img/new/TermsofService.pdf" target="_blank">Terms & Conditions</a>
                <div className='suppoted-by'>
                <span>Supported by: </span>
                <a href="https://www.chitkara.edu.in/" target="_blank">
                    <img className='chitkara-main' src="../../../../images/chitkara-logo.png" alt="Chitkara Logo" />
                    <img src="../../../../images/chitkara-fevicon.png" alt="Chitkara Logo" width="70" />
                </a>
                </div>
            </div>
            <div className='social-links'>
                <a href="https://www.instagram.com/sd.academia/" target="_blank">
                    <img src="../../../../images/instagram.svg" alt="Icon" />
                </a>
                <a href="http://www.twitter.com/@sdfinearts" target="_blank">
                    <img src="../../../../images/twitter.svg" alt="Icon" />
                </a>
                <a href="https://m.facebook.com/sdartacademia" target="_blank">
                    <img src="../../../../images/facebook.svg" alt="Icon" />
                </a>
                <a href="https://www.youtube.com/channel/UCJK_NTm_5zQyu6CoY7dHPPQ?app=desktop" target="_blank">
                    <img src="../../../../images/youtube.svg" alt="Icon" />
                </a>
            </div>
            <div className='copy-right'>
                <span>© sdacademia, Inc. 2021</span>
            </div>
        </div>
    )

};

export default Footer;
