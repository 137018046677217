import React from 'react';
import FixedContent from '../components/FixedContent/FixedContent';
import Footer from '../components/Footer/Footer';
import Header from '../components/Header/Header';
import BannerSection from './components/BannerSection';

const Login =(props)=> {

        return (
            <div className='main'>
                <Header butontext="Sign In"/>
                <BannerSection />
                <FixedContent/>
                <Footer/>
            </div>
        )

};

export default Login;