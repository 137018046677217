import React from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import AuthRoute from "./views/components/AuthRoute";
import MainRoute from './views/components/MainRoute';
import PaymentRoute from './views/components/PaymentRoute';



function App() {
  return (
    <BrowserRouter>
      <Switch>
        <MainRoute path="/phone" name="PhoneNumber"/>
          <PaymentRoute path="/payment" name="Payment" />
          <AuthRoute path="/" name="Login" />
      </Switch>
    </BrowserRouter>


  );
}

export default App;
