import React from 'react';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import PaymentSection from './components/PaymentSection';

const Payment =(props)=> {

        return (
            <div className='main'>
                <Header skiptext="Skip"/>
                <PaymentSection/>
                <Footer/>
            </div>
        )

};

export default Payment;