import React from 'react';

const SimpleInput = (props) => {

    return (
        <div className="simple-input">
            <input
                className={props.className}
                type={props.type}
                placeholder={props.placeholder}
                name={props.name}
                value={props.value}
                onChange={props.onChange}
                id={props.id}
            />
            {props.errors &&
                <span className="validation-error">{props.errors}</span>
            }
        </div>
    )
}

export default SimpleInput;