import React from 'react';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";

const SimpleButton =(props)=> {

        if (props.loading) {
            return (
                <button className="defalut-loading-button" variant="primary" type="button">
                    <Loader
                        type="Rings"
                        color="#ffffff"
                        height={50}
                        width={50}
                        timeout={3000}
                    />
                    {props.name}
                </button>
            )
        }
        return (
            <button onClick={props.onClick} value={props.value}  className="defalut-button" variant="primary" type="button">
                {props.name}
            </button>
        )

};

export default SimpleButton;