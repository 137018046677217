import React, {useState} from 'react';
import SimpleButton from '../../components/Buttons/SimpleButton';
import SimpleInput from '../../components/Input/SimpleInput';
import axios from "axios";

const LoginForm = (props) => {

    const [input, setInput] = useState({});
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);

    const handleChange = (event) => {
        let i = {...input};
        i[event.target.name] = event.target.value;
        setInput(i);
    }

    //Form Submit
    const handleSubmit = async (event) => {
        event.preventDefault();
        if (validate()) {
            setLoading(true);
            try {
                let response = (await axios.post(`https://learnresin.in/api/v1/user/register/v2`, input)).data
                if (response.success) {
                    localStorage.setItem("user", JSON.stringify(response.response));
                    setTimeout(() => {
                        window.location.href = "/payment";
                        setLoading(false);
                    }, 2000)
                } else {
                    let errors = {};
                    errors.email = response.error.description;
                    setErrors(errors);
                    setLoading(false);
                }
            } catch (e) {
                console.log(e);
                setLoading(false);
            }


        }
    }

    // Validations
    const validate = () => {

        let errors = {};
        let isValid = true;

        if (!input["fullName"]) {
            isValid = false;
            errors["fullName"] = "*Full Name is required";
        }

        if (!input["email"]) {
            isValid = false;
            errors["email"] = "*Email Address is required";
        }

        if (typeof input["email"] !== "undefined") {

            var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (!pattern.test(input["email"])) {
                isValid = false;
                errors["email"] = "*Email field is not a valid email address.";
            }
        }

        if (!input["phone"]) {
            isValid = false;
            errors["phone"] = "*phone Number is required";
        } else {
            const regex = new RegExp("^([1-9]{1}[0-9]{9})$");
            if (!regex.test(input["phone"])) {
                isValid = false;
                errors["phone"] = "*Please enter a valid phone number";
            }
        }

        setErrors(errors);
        return isValid;
    }

    return (
        <div className='login-form'>
            <SimpleInput
                className={errors.fullName ? "form-control error" : "form-control"}
                type="text"
                name="fullName"
                placeholder="Full Name"
                value={input.fullName}
                onChange={handleChange}
                errors={errors.fullName}
            />
            <SimpleInput
                className={errors.email ? "form-control error" : "form-control"}
                type="email"
                name="email"
                placeholder="Email Address"
                value={input.email}
                onChange={handleChange}
                errors={errors.email}
            />
            <SimpleInput
                className={errors.phone ? "form-control error" : "form-control"}
                type="text"
                name="phone"
                placeholder="Phone Number"
                value={input.phone}
                onChange={handleChange}
                errors={errors.phone}
            />
            <SimpleButton onClick={handleSubmit} loading={loading} name={loading ? "" : "Sign Up"}/>
        </div>
    )
}

export default LoginForm;
