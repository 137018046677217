import React from 'react';
import LoginForm from './LoginForm';

const BannerSection = (props) => {

    return (
        <div className='banner-section' style={{background: `url(${'../../../../images/banner.jpg'})`}}>
            <div className='section-overlay'>
                <div className='baner-overlay'>
                    <div className='container'>
                        <div className='banner-form-content'>
                            <div className='banner-form'>
                                <img src="../../../../images/sharktank.png" alt="Icon"/>
                                <h3>Shark Tank India Finalists</h3>

                                {/* Social Buttons */}
                                <div className='form-soical-buttons'>
                                    <button className='facebook-buton' onClick={()=>{
                                        window.location.href = "https://www.facebook.com/v2.3/dialog/oauth?response_type=code&client_id=345149320297848&scope=email&redirect_uri=https%3A%2F%2Flearnresin.in%2Fphone"
                                    }}>
                                        <img src="../../../../images/facebook-social.svg" alt="Facebook"/>
                                        <span>Continue with Facebook</span>
                                    </button>
                                    <button className='google-buton' onClick={()=>{
                                        window.location.href = "https://accounts.google.com/o/oauth2/v2/auth?scope=https%3A//www.googleapis.com/auth/userinfo.email https%3A//www.googleapis.com/auth/userinfo.profile&access_type=offline&include_granted_scopes=true&response_type=code&state=google&redirect_uri=https%3A//learnresin.in/phone&client_id=1098615622947-3n6411qfesuclm6p7ok4jpqttb7tg8im.apps.googleusercontent.com"
                                    }}>
                                        <img src="../../../../images/google-social.svg" alt="Google"/>
                                        <span>Continue with Google</span>
                                    </button>
                                </div>
                                <div className='Seperater-social'>
                                    <img src="../../../../images/line-sep.svg" alt="Line"/>
                                    <span>or</span>
                                    <img src="../../../../images/line-sep.svg" alt="Line"/>
                                </div>
                                <LoginForm/>
                            </div>
                            <div className='banner-content-box'>
                                <h4>Get access to <span>Hybrid Learning</span> with a blend of unlimited Live +
                                    Recorded <span>Sessions</span></h4>
                                <ul>
                                    <li>Top Courses include Resin Art, Concrete Art, Acrylic Art & Kids Course.</li>
                                    <li>Live Classes help you interact directly with the instructor.</li>
                                    <li>One to One doubt sessions & entrepreneurial guidance help you excel in your journey
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='banner-content-box for-mobile'>
                <h4>Get access to <span>Hybrid Learning</span> with a blend of unlimited Live +
                    Recorded <span>Sessions</span></h4>
                <ul>
                    <li>Top Courses include Resin Art, Concrete Art, Acrylic Art & Kids Course.</li>
                    <li>Live Classes help you interact directly with the instructor.</li>
                    <li>One to One doubt sessions & entrepreneurial guidance help you excel in your journey</li>
                </ul>
            </div>
        </div>
    )

};

export default BannerSection;
