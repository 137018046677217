
import React, {useEffect, useState} from 'react';

const PaymentSection = (props) => {

    const [input, setInput] = useState({full_name:'',phone_number:'',email:''});

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem("user"));
        if(user){
           setInput(user);
        }
    }, []);


    const onSubmit = ()=>{

    }

    function addMonths(date, months) {
        var d = date.getDate();
        date.setMonth(date.getMonth() + +months);
        if (date.getDate() != d) {
            date.setDate(0);
        }
        return date;
    }

    const [expiry, setExpiry] = useState(addMonths(new Date(), 12).toString());

    return (
        <>
            <div className='banner-section payment-web' style={{ background: `url(${'../../../../images/banner.jpg'})` }}>
                <div className='payment-overlay'>
                    <div className='payment-section-web'>
                        <div className='payment-header'>
                            <h4>&nbsp;</h4>
                        </div>
                        <div className='payment-content-box'>
                            <div className='payment-content'>
                                <img src="../../../../images/sharktank.png" alt="Banner" />
                                <ul>
                                    <li>Get access to high quality recorded sessions right from the instructor's desk</li>
                                    <li>Experience live classes by the instructors</li>
                                    <li>Learn the art of entrepreneurship and become a professional</li>
                                </ul>
                                <div className='payment-plan'>
                                    <div className='payment-amounts'>
                                        <span>Membership Plan</span>
                                        <span>₹1000.00/month</span>
                                    </div>
                                </div>
                                <div className='payment-border'>
                                    <img src="../../../../images/payment-line.svg" alt="Border" />
                                </div>
                                <div className='payment-total-amount'>
                                    <span>Total Amount</span>
                                    <span>₹12,000.00</span>
                                </div>
                                <div className='estimate-value'>
                                    Expiring on date: {expiry}
                                </div>
                                <form method={"post"} action={"https://learnresin.in/pay"}>
                                    <input type={"hidden"} name={"full_name"} value={input.full_name}/>
                                    <input type={"hidden"} name={"email"} value={input.email}/>
                                    <input type={"hidden"} name={"phone_number"} value={input.phone_number}/>
                                <div className='pay-now-button'>
                                    <button>Pay Now</button>
                                </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Payment For Mobile */}
            <div className="payment-for-mobile">
                <div className='payment-mobile-header'>
                    <h4>&nbsp;</h4>
                    <img src="../../../../images/sharktank.png" alt="Banner" />
                </div>
                <div className='mobile-payment-amounts'>
                    <div className='amount-box'>
                        <div className='amount-col'>
                            <span>Membership Plan</span>
                            <span>₹1000.00/month</span>
                        </div>
                    </div>
                    <div className='payment-border'>
                        <img src="../../../../images/white-line.svg" alt="Border" />
                    </div>
                    <div className='payment-total-amount'>
                        <span>Total Amount</span>
                        <span>₹12,000.00</span>
                    </div>
                    <div className='estimate-value'>
                        Expiring on date: {expiry}
                    </div>
                    <form method={"post"} action={"https://learnresin.in/pay"}>
                        <input type={"hidden"} name={"full_name"} value={input.full_name}/>
                        <input type={"hidden"} name={"email"} value={input.email}/>
                        <input type={"hidden"} name={"phone_number"} value={input.phone_number}/>
                    <div className='pay-now-button'>
                        <button>Pay Now</button>
                    </div>
                    </form>
                </div>
                <div className='list-points'>
                    <ul>
                        <li>Get access to high quality recorded sessions right from the instructor's desk</li>
                        <li>Experience live classes by the instructors</li>
                        <li>Learn the art of entrepreneurship and become a professional</li>
                    </ul>
                </div>
            </div>
        </>
    )

};

export default PaymentSection;
