import React from 'react';
import FixedContent from '../components/FixedContent/FixedContent';
import Footer from '../components/Footer/Footer';
import Header from '../components/Header/Header';
import PhoneBannerSection from './components/PhoneBannerSection';

const PhoneNumber =(props)=> {

        return (
            <div className='main'>
                <Header butontext="Sign In"/>
                <PhoneBannerSection/>
                <FixedContent/>
                <Footer/>
            </div>
        )

};

export default PhoneNumber;